/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getDeepValue as t } from "./object.js";
function s(s, e) {
  return s.replaceAll(/\$\{([^\s:}]*)(?::([^\s:}]+))?\}/g, (s, r) => {
    if ("" === r) return "$";
    const i = t(r, e);
    return (i ?? "").toString();
  });
}
class e {
  constructor(t, e, r = void 0) {
    this.name = t, this.details = r, this.message = (e && s(e, r)) ?? "";
  }
  toString() {
    return "[" + this.name + "]: " + this.message;
  }
}
export { e as default };