/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { clone as r } from "./lang.js";
function n(r, n, t = !1) {
  return f(r, n, t);
}
function t(r, n) {
  if (null != n) return n[r] || u(r.split("."), !1, n);
}
function e(r, n, t) {
  const e = r.split("."),
    i = e.pop(),
    f = u(e, !0, t);
  f && i && (f[i] = n);
}
function i(r, n) {
  if (null == r && null == n) return !1;
  if (null == r) return !0;
  if (null == n) return !0;
  if ("object" == typeof r) {
    if (Array.isArray(r)) {
      const t = n;
      if (r.length !== t.length) return !0;
      for (let n = 0; n < r.length; n++) if (i(r[n], t[n])) return !0;
      return !1;
    }
    if (Object.keys(r).length !== Object.keys(n).length) return !0;
    for (const t in r) {
      if (i(r[t], n[t])) return !0;
    }
    return !1;
  }
  return r !== n;
}
function u(r, n, t) {
  let e = t;
  for (const i of r) {
    if (null == e) return;
    if (!(i in e)) {
      if (!n) return;
      e[i] = {};
    }
    e = e[i];
  }
  return e;
}
function f(n, t, e) {
  return t ? Object.keys(t).reduce((n, i) => {
    let u = n[i],
      l = t[i];
    return u === l ? n : void 0 === u ? (n[i] = r(l), n) : (Array.isArray(l) || Array.isArray(n) ? (u = u ? Array.isArray(u) ? n[i] = u.slice() : n[i] = [u] : n[i] = [], l && (Array.isArray(l) || (l = [l]), e ? l.forEach(r => {
      u.includes(r) || u.push(r);
    }) : n[i] = l.slice())) : l && "object" == typeof l ? n[i] = f(u, l, e) : n.hasOwnProperty(i) && !t.hasOwnProperty(i) || (n[i] = l), n);
  }, n || {}) : n;
}
export { n as deepMerge, t as getDeepValue, i as notDeepEqual, e as setDeepValue };