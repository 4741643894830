/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getDeepValue as t } from "./object.js";
const e = /\{([^}]+)\}/g;
function n(t) {
  return t ?? "";
}
function r(r, o) {
  return o ? r.replaceAll(e, "object" == typeof o ? (e, r) => n(t(r, o)) : (t, e) => n(o(e))) : r;
}
function o(t, e) {
  return t.replaceAll(/([.$?*|{}()[\]\\/+\-^])/g, t => e?.includes(t) ? t : `\\${t}`);
}
function l(t) {
  let e = 0;
  for (let n = 0; n < t.length; n++) e = (e << 5) - e + t.charCodeAt(n), e |= 0;
  return e;
}
let u;
function c(t) {
  u ??= new DOMParser();
  return u.parseFromString(t || "", "text/html").body.innerText || "";
}
function i(t, e) {
  return new RegExp(`{${e}}`, "ig").test(t);
}
function f(t, ...e) {
  let n = t[0];
  for (let r = 0; r < e.length; ++r) n += e[r] + t[r + 1];
  return n;
}
export { o as escapeRegExpString, l as numericHash, r as replace, f as safeToString, c as stripHTML, i as templateHasKey };